import { defineStore } from 'pinia';
import type { CreateVehicleReq, VehicleListingReq } from '../repository/types/request';
import type { IApiInstance } from '../types/plugins';
import type { Vehicle } from '../types/vehicle';
import type { VehicleModel } from '../types/vehicle-model';

export const useVehicleStore = defineStore({
	id: 'vehicleStore',
	state: () => ({
		loading: false as boolean,
		modelListing: [] as VehicleModel[],
		myVehicles: [] as Vehicle[],
		myDefaultVehicle: null as Vehicle | null,
		errors: [] as string[],
	}),
	actions: {
		async fetchModelListing() {
			try {
				this.loading = true;

				const $api = useNuxtApp().$api as IApiInstance;
				const vehicleModelsReq: VehicleListingReq = {
					skip: 0,
					take: 100,
				};
				const response = await $api.vehicle.getVehicleModels(vehicleModelsReq);

				// const apiResult = await new ApiService().getVehicleModelListingApi(
				// 	{
				// 		skip: 0,
				// 		take: 100,
				// 	},
				// 	authStore.accessToken!,
				// );

				if (response != null && response.length > 0) {
					this.modelListing = [...response];
				}
			} catch (error) {
				console.error(error);
			} finally {
				this.loading = false;
			}
		},

		async fetchDefaultVehicle() {
			try {
				this.loading = true;

				const $api = useNuxtApp().$api as IApiInstance;
				const response = await $api.vehicle.getDefaultVehicle();

				// const apiResult = await new ApiService().getDefaultVehicleApi(authStore.accessToken!);

				if (response != null) {
					this.myDefaultVehicle = response;
				}
			} catch (error) {
				console.error(error);
			} finally {
				this.loading = false;
			}
		},
		async createVehicle(vehicleData: CreateVehicleReq): Promise<boolean> {
			let result = false;
			this.errors = [];
			try {
				this.loading = true;

				const $api = useNuxtApp().$api as IApiInstance;
				const response = await $api.vehicle.createVehicle(vehicleData);

				if (response != null) {
					this.myVehicles.push(response);
					result = true;
				} else {
					result = false; // Return false if apiResult is null
				}

				return result;
			} catch (error) {
				console.error(error);
				result = false; // Return false in case of an error
				this.errors.push(String(error));
				return result;
			} finally {
				this.loading = false;
			}
		},

		async getMyVehicles() {
			try {
				this.loading = true;

				const $api = useNuxtApp().$api as IApiInstance;
				const vehicleModelsReq: VehicleListingReq = {
					skip: 0,
					take: 50,
				};
				const response = await $api.vehicle.getVehicles(vehicleModelsReq);

				if (response) {
					this.myVehicles = response; // Assuming you want to store the fetched vehicles
					return response;
				}
			} catch (error) {
				console.error(error);
			} finally {
				this.loading = false;
			}
			return undefined; // Return undefined if there's an error or no result
		},
	},
});
